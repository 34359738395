const Customer1 = "/assets/img/customer1.png";
const Customer2 = "/assets/img/customer2.png";
const Customer3 = "/assets/img/customer3.png";
const Customer4 = "/assets/img/customer4.png";
const Customer5 = "/assets/img/customer5.svg";
const Mail = "../lotties/mail.json";
const mailImg = "/assets/img/mail.svg";
const animationData = "../lotties/web.json";
const appDevelopment = "../lotties/app.json";
const digital = "../lotties/digital.json";
const Ecommerce = "../lotties/commerce.json";
const content = "../lotties/content.json";
const Ui = "../lotties/ui.json";
const logo = "/assets/img/logo-light.png";
const slideImg1 = "/assets/img/slide1.jpg";
const slideImg2 = "/assets/img/slide2.jpg";
const slideImg3 = "/assets/img/slide3.jpg";
const slideImg4 = "/assets/img/slide4.jpg";
const whtsappImg = "/assets/img/whtsapp.svg";
const addressImg = "/assets/img/address.svg";
const codingImg = "/assets/img/coding.svg";
const frontImg = "/assets/img/front.png";
const angular = "/assets/img/angular.png";
const reactImgIcon = "/assets/img/react.png";
const blogBannerImg = "/assets/img/blogbanner.png";
const cyberImg = "/assets/img/cyber.png";

export const sitelogo = logo;
export const slide1 = slideImg1;
export const slide2 = slideImg2;
export const slide3 = slideImg3;
export const slide4 = slideImg4;
export const img1 = Customer1;
export const img2 = Customer2;
export const img3 = Customer3;
export const img4 = Customer4;
export const img5 = Customer5;
export const mail = Mail;
export const WebDeveloment = animationData;
export const AppDeveloment = appDevelopment;
export const DigitalMarketing = digital;
export const ECommerce = Ecommerce;
export const contentImg = content;
export const uiImg = Ui;
export const whtsapp = whtsappImg;
export const addres = addressImg;
export const mailIcon = mailImg;
export const coding = codingImg;
export const Front = frontImg;
export const Angular = angular;
export const React = reactImgIcon;
export const blogBanner = blogBannerImg;
export const cyber = cyberImg;
